import { Input } from "@canopyinc/aura";
import { ControllerRenderProps } from "react-hook-form";
import BaseNumberFormat from "react-number-format";

type MFACodeFormatProps = BaseNumberFormat["props"] & { fieldRef: ControllerRenderProps["ref"] };

export const MFACodeFormat = ({ fieldRef, ...props }: MFACodeFormatProps) => (
  <BaseNumberFormat
    maxLength={6}
    customInput={Input}
    placeholder="XXXXXX"
    {...props}
    // dummy focus function that fixes interaction between react-hook-form & number format component
    ref={() => {
      fieldRef?.({
        focus: () => {},
      });
    }}
  />
);
