import React, { useState } from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";

// ui
import { Alert, Button, InputWrapper, PasswordInput, Text } from "@canopyinc/aura";
import parseCognitoError, { CognitoError } from "@/api/auth/parseCognitoError";
import axios from "axios";
import { getEnvConfig } from "@/config/environments";
import { TApi } from "@/api/useApi";
import { useI18n } from "@/hooks/useI18n";
import { Controller, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { validatePassword } from "@/utils/auth";
import { PasswordValidation } from "@/components/PasswordValidation/PasswordValidation";
import { track } from "@/libs/analytics";

type SetPasswordFormProps = {
  to?: string;
  user?: CognitoUser;
};

export const SetPasswordForm = ({ to, user }: SetPasswordFormProps) => {
  const router = useRouter();
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.auth?.set_password;
  const [error, setError] = useState<string | null>(null);
  const { control, formState, handleSubmit, watch, ...rest } = useForm({
    mode: "all",
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const email = router?.query?.["email"]?.toString() ?? "";

  const setPassword = async (values: { newPassword: string }) => {
    try {
      const confirmedUser = await Auth.completeNewPassword(user, values.newPassword);
      // Confirm user in db
      const accessToken = await confirmedUser.getSignInUserSession().getAccessToken().getJwtToken();
      const url = `/api_users`;
      const api = apiClient(accessToken);
      await confirmUser(api, url);
      track("Set Password");
      // forward along
      router.push(`signin?email=${encodeURIComponent(email)}&set-password-success=true`);
    } catch (err) {
      const cleanedErr = parseCognitoError(err as CognitoError);
      setError(cleanedErr);
    }
  };

  if (!user) {
    router.push("/signin");
  }

  return (
    <div className="w-full">
      <div className="mb-4">
        <Text as="h2">{dictionary?.title}</Text>
      </div>
      <div className="mb-4">{error ? <Alert color="danger">{error}</Alert> : null}</div>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit(setPassword)}>
        <InputWrapper
          labelText={dictionary?.fields?.new_password?.label ?? ""}
          htmlFor="password"
          id="password_wrapper"
          required
        >
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: dictionary?.fields?.new_password?.validation?.required,
              max: 46,
              validate: validatePassword(dictionary?.fields?.new_password?.validation),
            }}
            render={({ field }) => (
              <>
                <PasswordInput
                  {...field}
                  testid="set-new-password"
                  autoComplete="password"
                  invalid={Boolean(formState.errors.newPassword)}
                  error={formState?.errors?.newPassword?.message}
                />
                <PasswordValidation show={Boolean(formState.errors.newPassword)} password={watch("newPassword")} />
              </>
            )}
          />
        </InputWrapper>

        <InputWrapper
          labelText={dictionary?.fields?.confirm_new_password?.label ?? ""}
          htmlFor="password"
          id="password_wrapper"
          required
        >
          <Controller
            name="confirmNewPassword"
            control={control}
            rules={{
              required: dictionary?.fields?.confirm_new_password?.validation?.match,
              validate: (confirmedPassword) =>
                !confirmedPassword ||
                watch("newPassword") === confirmedPassword ||
                dictionary?.fields?.confirm_new_password?.validation?.match,
            }}
            render={({ field }) => (
              <PasswordInput
                {...field}
                testid="confirm-new-password"
                autoComplete="password"
                invalid={Boolean(formState.errors.confirmNewPassword)}
                error={formState?.errors?.confirmNewPassword?.message}
              />
            )}
          />
        </InputWrapper>

        <Button
          testid="set-password-submit"
          loading={formState.isSubmitting}
          disabled={!formState.isValid}
          variant="primary"
          fullWidth
          classNames={{ button: "my-2" }}
        >
          {dictionary?.actions?.submit}
        </Button>
      </form>
    </div>
  );
};

const apiClient = (accessToken: string) => {
  return axios.create({
    baseURL: getEnvConfig().APIDomain,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const confirmUser = (api: TApi, url: string) => {
  return api.put(url);
};
